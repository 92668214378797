<template>
  <div class="readme-article">
    <h2 id="名片">2.名片</h2>
    <h3 id="名片列表">2.1名片列表</h3>
    <p>
      查看名片列表，包含姓名、头像、微信二维码、电话、邮箱等，左上角新增按钮可新增名片，已添加的名片管理员可重新编辑。
    </p>
    <p>
      <img src="@/assets/img/card/2-1.png" />
    </p>
    <p>
      <img src="@/assets/img/card/2-2.png" />
    </p>
    <h3 id="通用设置">2.2通用设置</h3>
    <p>
      系统通用设置，包括引导页头部背景图、名片详情点击跳转其他小程序的背景图及要跳转的小程序appId、引导页的公司logo、地理位置、公司名称及公司简介等。
    </p>
    <p>
      <img src="@/assets/img/card/2-3.png" />
    </p>
    <h3 id="表单模板">2.1表单模板</h3>
    <p>
      系统自定义的试用表单模板列表，只支持一个表单模板，管理员可重新编辑表单内容。
    </p>
    <p>
      <img src="@/assets/img/card/2-4.png" />
    </p>
  </div>
</template>

<script>
export default {
  name: "Card2-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>